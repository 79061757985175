<template>
    <div>
        <v-container class="pub mx-auto">
            <div class="titles">
                <p class="tl pt-1"><router-link class="book_main_title" :to="'/lib?lang='+$i18n.locale">{{ $t("pub") }}</router-link> » {{cat_title}}</p>
            </div>

            <v-layout row style="justify-content: center;" v-if="loading">
                <v-flex xl4 lg6 md12 sm12 xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                <v-flex xl4 lg6 md12 sm12 xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
                <v-flex xl4 lg6 md12 sm12 xs12><v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions"></v-skeleton-loader></v-flex>
            </v-layout>
            
            <v-row justify="center" v-else>
                <template v-for="item in data">
                    <v-col cols="12" xl="4" lg="6" md="12" sm="12" :key="item.title" class="hidden-sm-and-down">
                        <v-card outlined elevation="3" rounded :key="item">
                            <v-list-item >
                                <!-- <v-list-item-avatar rounded tile width="188" height="268" color="grey" ></v-list-item-avatar> -->
                                <v-list-item-avatar rounded tile width="188" height="268" color="grey" ><v-img rounded :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/200/'+item.img" ></v-img></v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title class="mb-3 t1 card_title"> {{item.title}} </v-list-item-title>
                                    <v-list-item-subtitle class="t1 card_det">
                                        <div class="tooltip_cat"> {{$t("pub_ver")}} : {{item.ver}}</div>
                                        <div class="tooltip_cat"> {{$t("pub_size")}} : {{item.size}}</div>

                                        <div class="tooltip_cat" v-if="item.date"> {{$t("pub_date")}} : {{item.date}}</div>
                                        <div class="tooltip_cat" v-else> {{$t("pub_date")}} : {{item.time|FormatDate}}</div>
                                        
                                        <div class="tooltip_cat"> {{$t("views_count")}} : {{item.hits}}</div>
                                    </v-list-item-subtitle>

                                        <template v-if="$i18n.locale === 'ar'">
                                            <v-flex sm12 mx-1>
                                                <v-card-actions class="padding" :style="$vuetify.rtl ? 'justify-content: left' : 'justify-content: right'">
                                                    <v-btn class="black--text action_btn mx-3 px-8 py-3" @click="view(item.id, item.pdf, 'pdf')"> {{$t('pub_view')}} </v-btn>
                                                    <v-btn class="black--text action_btn px-8 py-3" @click="view(item.id, item.down, 'down')"> <img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download')}} </v-btn>

                                                </v-card-actions>
                                            </v-flex>
                                        </template>
                                        <template v-else>
                                            <v-flex xs12>
                                                <v-btn width="90%" class="black--text action_btn mx-3 px-8 py-3" @click="view(item.id, item.pdf, 'pdf')"> {{$t('pub_view')}} </v-btn>
                                            </v-flex>
                                            <v-flex xs12 mt-2>
                                                <v-btn width="90%" class="black--text action_btn mx-3 px-8 py-3" @click="view(item.id, item.down, 'down')"><img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download')}} </v-btn>
                                            </v-flex>
                                        </template>
                                        
                                </v-list-item-content>

                            </v-list-item>
                        </v-card>
                    </v-col>
                    
                    <v-col cols="12" :key="item" class="mb-6 px-8 hidden-md-and-up">
                        <v-card outlined elevation="3" rounded :key="item">
                            <v-img rounded class="mx-auto mt-3 px-3" max-height="400" max-width="450" :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/400/'+item.img" ></v-img>
                            <v-list-item >
                                
                                <v-list-item-content class="text-center">
                                    <v-list-item-title class="mb-2 t1 card_title_m"> {{item.title}} </v-list-item-title>
                                    <v-list-item-subtitle class="t1 card_det_m">
                                        <div class="tooltip_cat"> {{$t("pub_ver")}} : {{item.ver}}</div>
                                        <div class="tooltip_cat"> {{$t("pub_size")}} : {{item.size}}</div>
                                        <!-- <div class="tooltip_cat"> {{$t("pub_date")}} : {{item.time|FormatDate}}</div> -->

                                        <div class="tooltip_cat" v-if="item.date"> {{$t("pub_date")}} : {{item.date}}</div>
                                        <div class="tooltip_cat" v-else> {{$t("pub_date")}} : {{item.time|FormatDate}}</div>

                                        <div class="tooltip_cat"> {{$t("views_count")}} : {{item.hits}}</div>
                                    </v-list-item-subtitle>
                                    
                                    <v-layout row my-6>
                                        <v-flex xs12>
                                            <!-- TODO -->
                                            <!-- <v-btn width="90%" class="black--text action_btn mx-3 px-8 py-3" target="_blank" :href="'https://alkafeel.net/publications/pdf/'+item.pdf"> {{$t('pub_view')}} </v-btn> -->
                                            <v-btn width="90%" class="black--text action_btn mx-3 px-8 py-3" @click="view(item.id, item.pdf, 'pdf')"> {{$t('pub_view')}} </v-btn>

                                        </v-flex>
                                        <v-flex xs12 mt-2>
                                            <v-btn width="90%" class="black--text action_btn px-8 py-3" @click="view(item.id, item.down, 'down')"><img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download')}} </v-btn>
                                            <!-- <a style="text-decoration: none;" :href="'https://alkafeel.net/publications/down/'+item.down">
                                                <v-btn width="90%" class="black--text action_btn px-8 py-3"> 
                                                    <img style="padding:3px; height:20px" src="/assets/img/download.svg"> {{$t('download')}} 
                                                </v-btn>
                                            </a> -->
                                        </v-flex>
                                    </v-layout>
                                            
                                </v-list-item-content>

                            </v-list-item>
                        </v-card>
                    </v-col>

                </template>
            </v-row>

            <div v-if="getMore_btn" style="text-align: center;">
                <v-btn width="180" height="50" style="justify-content: center;" :loading="btnLoader" class="ma-2 tl" color="#145C5E" @click="getMore('click')">
                    <p class="btnT white--text mb-0 tl">{{ $t('view_more') }}</p>
                </v-btn>
            </div>
        </v-container>
        
    </div>
</template>

<script>
// import axios from "axios";
// import pdf from 'vue-pdf'
export default {
    data() {
        return {
            data: [],
            cat_title: '',
            loading: true,
            getMore_btn: true,
            bookPage: 1,
            btnLoader: false,
            attrs: {
                class: 'ma-3',
                boilerplate: false,
                elevation: 2,
            },
            axios_load: false,
            offsetTop: 0,
            autoScroll: 0
        }
    },
    watch: {
        offsetTop (val) {
            this.callbackFunc()
        }
    },
    created() {
        document.title =this.$t("pub"); 
        this.ini();
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll (e) {
            setTimeout(() => {
                if (!this.axios_load) {this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;}
            }, 700);
        },
        isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        callbackFunc() {
            let el = document.querySelector('.btnT');
            if (this.isElementInViewport(el) && this.autoScroll < 4) {this.getMore('auto');}
        },


        ini () {
            this.$axios.get('pub/byCatId/'+this.$route.params.id)
            .then (d => {
                this.loading = false;
                this.data = d.data.data;
                this.cat_title = d.data.data[0].main_title;
                if (d.data.data.length < 15) {
                    this.getMore_btn = false;
                }
                // console.log(d.data.data);
            })
        },
        getMore(type) {
            // reset page autoscroll counter
            if (type === 'click') {
                this.autoScroll = 0
            } else {
                this.autoScroll++
            }

            this.btnLoader = true;
            this.axios_load = true;
            var p = this.bookPage +1;
            this.$axios.get("pub/byCatId/"+this.$route.params.id+"?page=" + p, {})
                .then(d => {
                    this.axios_load = false;
                    this.btnLoader = false;
                    this.loading = false;
                    this.bookPage++;
                    this.data = this.data.concat(d.data.data);
                    if (d.data.data.length < 15) {
                        this.getMore_btn = false;
                    }
                })
        },
        view(id, pdf, ext) {
            
            if (this.checkCookie('publications', id)) {

                window.open("https://alkafeel.net/publications/"+ext+"/"+pdf, "_blank");
                
            } else {
                this.setCookie('publications', id);
                
                this.$axios.post("pub/stat/"+id, {id})
                .then(window.open("https://alkafeel.net/publications/"+ext+"/"+pdf, "_blank"))
            }
            
        },
        setCookie(cname, cvalue) {
            const d = new Date();
            d.setTime(d.getTime() + (1*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie(cname, id) {
            let username = this.getCookie(cname);
            if (username != "") {
                if (id === username) {
                    return true
                } else {
                    return false
                }
            }
        }
    },
    components: {
        // pdf
    },
    filters: {
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        }
    }
}
</script>

<style scoped>
.pub {
  width: 100%;
  padding-top: 30px;
  /* max-width: 1260px; */
}
.pub .titles {
    text-align: center;
}
.pub .titles p, .pub .titles a {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  font-family: 'cairo_bold';
}
.card_title {
    font-size: 26px;
    font-weight: 700;
    color:#000
}
.book_main_title {
    text-decoration: none; 
    color:#000
}
.card_det {
    font-size: 16px;
    font-weight: 700;
    line-height: 37px;
}
.card_det_m {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
}
.card_title_m {
    font-size: 20px;
    font-weight: 600;
    color:#000
}
.padding {
    padding: 10px 20px
}
.action_btn {
    background: #FFF!important;
    border: solid 2px #B1BD52;
    font-weight: 18px!important;
    font-weight: 700;
}
</style>